.gallery {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.gallery .gallery-icon {
    aspect-ratio: 1/1;
    position: relative;
}

.gallery .gallery-icon img {
    margin: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
}

.gallery .gallery-item {
    margin: 0;
}

.gallery .gallery-caption {
    margin: 0;
    text-align: center;
}

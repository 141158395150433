.prose .highlights li {
    @apply font-semibold text-black;
}

.prose .highlights {
    @apply border-b mb-6
}

/* wordpress styles */

.wp-block-image .alignright {
    @apply float-right ml-8 mt-2 w-4/12;
}

.wp-block-image .alignright img {
    @apply m-0 p-0;
}

.wp-block-embed figcaption,
.wp-block-image figcaption {
    @apply break-words m-0 p-0 text-xs text-center;
}

.size-medium {
    @apply text-sm;
}

.wp-block-embed {
    overflow-wrap: break-word;
    @apply w-full text-center mx-auto py-4 m-0;
}

.wp-block-embed.is-type-video {
    @apply aspect-w-16 aspect-h-9
}


.wp-block-media-text {
    direction: ltr;
    display: grid;
    grid-template-columns: 50% 1fr;
    grid-template-rows: auto;
}

.wp-block-media-text .wp-block-media-text__media {
    grid-column: 1;
    grid-row: 1;
    margin: 0;
}

.wp-block-media-text__content {
    direction: ltr;
    grid-column: 2;
    grid-row: 1;
    padding: 0 8%;
    word-break: break-word;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .card {
        @apply bg-gray-50 border border-gray-200 rounded-xl p-4 mb-4 sm:p-6 md:p-8 sm:mb-6 md:mb-8;
    }
}

@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

.sticky {
    align-self: flex-start;
}

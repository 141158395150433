.ad-wrapper:has(> .ad-slot:empty),
.ad-wrapper:has(> .ad-slot[style*='display: none;']) {
    display: none;
}

.ad-slot:empty {
    display: none;
}

.ad-slot {
    overflow: hidden;
    @apply flex flex-row justify-start items-center justify-center relative;
}

.ad-slot-label:before {
    @apply text-gray-400 text-xs leading-6;
    content: 'advertisement';
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
}

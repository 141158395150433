.newsletter div.prose {
    margin-top: -6pt;
}
.newsletter .row-1 {
    display: none;
}
.newsletter .row {
    margin: -75pt 0 !important;
}
.newsletter .pad {
    margin: 0 !important;
}
.newsletter .our-class {
    @apply my-6;
}
.newsletter .text_block {
    margin-top: 5px;
}
.newsletter .column {
    @apply py-4;
}
.newsletter .alignment {
    @apply py-4 px-2.5 items-center mx-auto;
}
.newsletter div.mobile_hide {
    display: none;
}
.newsletter a.read-all {
    letter-spacing: 0.05em;
    font-weight: bold;
    text-transform: uppercase;

    background-color: #5b35d5;
    color: #fff;
    padding: 5px 8px 5px 8px;
    font-size: 12px;
    border-radius: 4px;
    margin-left: 15px;
}

.newsletter .prose img {
    width: 100% !important;
}

.newsletter .prose .center {
    width: 100% !important;
}
.newsletter .prose .img-container.center.fixedwidth a img {
    width: 50% !important;
}

@font-face {
    font-family: 'Tiempos Headline';
    src: url('/fonts/tiempos-headline-semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

.font-headline {
    font-family: "Tiempos Headline", serif;
}

@font-face {
    font-family: 'Soehne';
    src: url('/fonts/soehne-web-700.woff2') format('woff2'), url('/fonts/soehne-web-700.woff') format('woff');
    font-weight: 700;
    font-display: swap
}

@font-face {
    font-family: 'Soehne';
    src: url('/fonts/soehne-web-400.woff2') format('woff2'), url('/fonts/soehne-web-400.woff') format('woff');
    font-weight: 400;
    font-display: swap
}

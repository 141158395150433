a {

}

a:hover {
    @apply no-underline;
}

.link-primary {
    @apply text-primary;
}

.link-primary:hover {
    @apply text-purple-500;
}

.link-gray {
    @apply text-gray-500;
}

.link-gray:hover {
    @apply text-gray-600;
}

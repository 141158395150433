.embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
}

.embla__viewport.is-dragging {
    cursor: grabbing;
}

.embla__container {
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.embla__slide {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 10px;
}

.embla__slide__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.embla__button {
    -webkit-appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
}
.embla__buttons {
    transform: translateY(-50%);
}
.embla__button {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 4rem;
    height: 4rem;
}
.embla__button:disabled {
    opacity: 0.3;
}
.embla__button__svg {
    width: 65%;
    height: 65%;
}

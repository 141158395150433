.form-wrapper .form-title {
    display: none;
}

.form-wrapper .form-fields > div {
    @apply relative p-2
}

.form-wrapper .form-fields > div > div.name {
    @apply mb-2
}

.form-wrapper .form-fields .form-field:has(span.error-message) {
    @apply border-l-4 border-red-100 rounded-sm;
}

.form-wrapper .form-fields .form-field label {
    @apply block text-sm font-medium text-gray-700 mb-2;
}

.form-wrapper .form-fields .checkbox {
    @apply flex flex-row align-middle gap-2 py-1
}

.form-wrapper .form-fields .checkboxes input[type=checkbox] {
    @apply float-left mr-1
}

.form-wrapper .form-fields input[type=text],
.form-wrapper .form-fields input[type=phone],
.form-wrapper .form-fields input[type=name],
.form-wrapper .form-fields input[type=email],
.form-wrapper .form-fields input[type=password],
.form-wrapper .form-fields input[type=date],
.form-wrapper .form-fields input[type=search],
.form-wrapper .form-fields input[type=time],
.form-wrapper .form-fields input[type=number],
.form-wrapper .form-fields select {
    @apply block px-3 py-2 w-full rounded-none border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm;
}

.form-wrapper .form-fields .error-message {
    @apply block text-red-400
}

.form-wrapper .form-fields .gform_validation_container {
    @apply mt-4
}

.form-wrapper button[type=submit] {
    content: 'Submit';
    @apply mt-12 px-4 py-2 bg-primary text-white uppercase;
}

.form-confirmation {
    @apply w-full
}

div.form-wrapper > div.footer {
    @apply px-2
}

.gform_confirmation_message {
    @apply w-full mt-6 p-6 bg-green-50 border border-green-100 text-green-500 text-center;
}


/* Hide honeypot fields */
.gform_validation_container,
.gform_wrapper .gform_validation_container,
body .gform_wrapper li.gform_validation_container,
body .gform_wrapper .gform_body ul.gform_fields li.gfield.gform_validation_container,
body .gform_wrapper ul.gform_fields li.gfield.gform_validation_container {
    display: none !important;
    position: absolute !important;
    left: -9000px;
}
